import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import { get, post } from "../utils/Api";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export default function useEvents({
  offset = 0,
  limit = 1000,
  search = "",
} = {}) {
  const { user } = useAuth();
  const token = user?.token;
  const queryClient = useQueryClient();

  const params = useParams();

  const eventsQuery = useQuery({
    queryKey: ["eventscashless", token],
    queryFn: () =>
      post("/eventscashless", {
        orgid: user.orgid,
        token,
        offset,
        limit,
        filter: search,
      }),
  });

  const createEventMutation = useMutation({
    mutationFn: (data) =>
      post("/createevent", {
        ...data,
        orgid: user.orgid,
        token,
      }),
    onSettled: () => {
      queryClient.invalidateQueries(["eventscashless"]);
    },
  });

  const event = useMemo(() => {
    return eventsQuery.data?.find(
      (event) => parseInt(event.eventid) === parseInt(params?.eventid)
    );
  }, [eventsQuery.data, params?.eventid]);

  return {
    ...eventsQuery,
    eventid: params?.eventid,
    event,
    createEventMutation,
    createEvent: createEventMutation.mutate,
  };

  // const currentEvent = useMemo(() => {
  //   return eventsQuery?.data?.find(
  //     (event) => event.eventid === params?.eventid
  //   );
  // }, [eventsQuery?.data, params?.eventid]);

  // return { ...eventsQuery, currentEvent };
}
